import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/Users/pgegenfurtner/Projekte/interness/atacama/atacama-packages/packages/web-core/src/templates/mdx/default.js";
import TeamMembers from '@interness/web-core/src/components/modules/TeamMembers';
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const Wrapper = makeShortcode("Wrapper");
const CTASection = makeShortcode("CTASection");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <Wrapper mdxType="Wrapper">
      <hr></hr>
      <h1>{`Über uns`}</h1>
      <p>{`Das Unternehmen wurde 1935 von der Familie Reich gegründet. 1992 zog das Geschäft zur jetzigen Adresse „Kornmarkt 16 - 04600 Altenburg“ um.
Im Jahr 2005 setzte sich die Inhaberfamilie Reich zur Ruhe und der Uhrmachermeister Marcel Aretz übernahm das Geschäft.
Im laufe der letzten Jahre wurden die Geschäftsräume neu und modern gestaltet. Das bis dahin schon feine Sortiment wurde mit internationalen Uhren und Schmuckmarken erweitert.
Der Umbau der Uhrmachermeister-Werkstatt nach Vorgaben internationalen Uhrenhersteller und eine Erweiterung der eigenen Goldschmiede-Werkstatt haben die Modernisierung des Geschäftes komplettiert.
4 Mitarbeiter/innen arbeiten heute in dem top-modernen Geschäft in Werkstatt und Verkauf.`}</p>
      <hr></hr>
      <TeamMembers mdxType="TeamMembers" />
    </Wrapper>
    <hr></hr>
    <CTASection mdxType="CTASection" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      